import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import FooterTitle from '../../components/common/footer-title'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import PageContainer from '../../components/common/page-container'

import Link from '../../components/common/link'

export default (props) => (
  <Layout title="Software as a Service">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>Software as a Service</Title>
      </TitleContainer>
    </BackgroundContainer>

    <PageContainer>
      <p>
        If you are looking to start a subscription-based software business,
        packagedby have a proven track record of delivering Software-as-a-Service solutions,
        working with clients across a variety of different sectors.
        We can take care of every aspect of the development process including using industry-standard
        tools and processes to develop and deliver your application.
      </p>
      <h2 id="subscriptions" className="mb-1">Payments and subscriptions</h2>
      <p>We have worked with a range of payment providers, and have extensive experience of building flexible subscription models for online businesses.</p>
      <p>From simple monthly plans for your customers, to per-user pricing with add-ons and usage limits – we have got you covered. We can either deeply integrate with a payment gateway, or <Link href="/services/integration">integrate with services</Link> which do all of the heavy lifting – so we can focus on your core business.</p>
      <h2 id="multi-tenant" className="mb-1">Multi-tenant applications</h2>
      <p>Segregate customers accounts, let all of your customers users login, but only give them access to that customers data. We have built many applications which revolve around the notion of a tenant or customer account, sometimes each user is the tenant, in other cases its a large client organisation that is the tenant, isolating their data from other customers.</p>
      <h2 id="enterprise-features" className="mb-1">Enterprise features</h2>
      <p>If you have a Software-as-a-Service product aimed at large organisations, we can bring in enterprise grade functionality to make working with large companies as easy as possible.</p>
      <ul>
        <li>
          <Link href="/services/software-as-a-service/single-sign-on" title="Single sign-on">
            Single-sign-on which integrates with active-directory, Google Apps or other providers
          </Link>.
        </li>
        <li>Fine grained permissions for your users</li>
        <li>Powerful reporting and analytics functionality</li>
        <li>GDPR tooling for your customers. If one of their users makes a subject access request, give your customers the power to manage that process.</li>
        <li>Integration with customer support tools, to help your users succeed on your platform.</li>
      </ul>
     </PageContainer>

     <BackgroundContainer header={props.data.footer}>
      <TitleContainer>
          <FooterTitle>Ready to start your SaaS project?</FooterTitle>

          <Link to="/contact" className="btn btn-lg mt-3 btn-primary">
            Get in touch
          </Link>
      </TitleContainer>
    </BackgroundContainer>
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "saas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footer: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
